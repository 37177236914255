import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Link from "../components/Link";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

const browser = typeof window !== "undefined" && window;

// markup
const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "404bg" }) {
        childImageSharp {
          fluid(maxWidth: 4096) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wp {
        mdaThemeOptions {
          themeSettings {
            navbarLogo {
              localFile {
                id
                childImageSharp {
                  gatsbyImageData
                }
                childSvg {
                  content {
                    data
                  }
                }
              }
            }
          }
        }
      }
    }
  `);


  return (
    browser && (
      <BackgroundImage
        fluid={data?.file.childImageSharp?.fluid}
        className="error404__background"
      >
        <main class="error404__wrapper">
          <title>Not found</title>
          <Link className="navbar__logo-wrapper" to="/">
            <div
              className="error404__logo"
              dangerouslySetInnerHTML={{
                __html:
                  data.wp.mdaThemeOptions.themeSettings.navbarLogo.localFile
                    .childSvg.content.data,
              }}
            />
          </Link>
          <h1 class="error404__header">Sorry, this page does not exist.</h1>
          <Link className="button-text -white-hover-red" to="/">
            Back to homepage
          </Link>
          .
          <p style={paragraphStyles} class="error404__text">
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in{" "}
                <code style={codeStyles}>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
          </p>
        </main>
      </BackgroundImage>
    )
  );
};

export default NotFoundPage;
