import React from "react";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";

const Link = ({
  type,
  file,
  link,
  to,
  target,
  title,
  before,
  children,
  after,
  ...props
}) => {
  let btnLink = { title: "", url: "#", target: "" };

  if (type === "file") {
    const data = useStaticQuery(graphql`
      query siteUrlQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `);
    const fileUrl = new URL(
      file?.localFile?.publicURL || "/404",
      data.site.siteMetadata.siteUrl
    );

    btnLink = {
      url: fileUrl.href,
      title: file.title,
      target: "_blank",
    };
  } else if (link) {
    btnLink = link;
  } else {
    btnLink = {
      url: to || "#",
      target: target || "",
      title: title || "",
    };
  }

  const isLinkInternal = /^\/(?!\/)/.test(btnLink.url);

  const linkContent = props.dangerouslySetInnerHTML ? null : (
    <>
      {before}
      {children || btnLink.title}
      {after}
    </>
  );

  return (
    <>
      {isLinkInternal ? (
        <GatsbyLink to={btnLink.url} target={btnLink.target} {...props}>
          {linkContent}
        </GatsbyLink>
      ) : (
        <a href={btnLink.url} target={btnLink.target} {...props}>
          {linkContent}
        </a>
      )}
    </>
  );
};

export default Link;
